angular.module('fingerink')
    .service('buyModal', function ($uibModal, session) {
        this.modalInstance = null;
        this.openModal = function (product) {
            let s = session.get();
            this.modalInstance = $uibModal.open({
                backdrop: 'static',
                templateUrl: 'modals/buyModal.tpl.html',
                controller: 'BuyCtrl',
                controllerAs: 'controller',
                animation: false,
                size: 'lg',
                keyboard: s.plan && !s.plan.isExpired,
                resolve: {
                    productos: productService => productService.getProducts(),
                    prices: productService => productService.getProductsPrices(),
                    countries: fingerinkService => fingerinkService.getCountries()
                }
            });

            return this.modalInstance;
        };

    }).controller('BuyCtrl', function ($scope, session, productos, prices, countries, $uibModalInstance, buyPlanModal) {

        var that = this;
        that.close =  () => $uibModalInstance.close();

        that.planActual = session.get().plan;
        that.country = countries.data.find(element => element.id == session.get().user.company.country.id);
        that.coin = that.country.coin;
        that.products = productos.data;
        that.prices = prices.data[that.coin];

        that.buy = product => buyPlanModal.openModal(product.id);

    });

